























































































import Vue from 'vue';
import _ from 'lodash';
import notificationMixin from '../../mixin/notification';
import store from '../../store/shop';
import ShopAPI from '../../common/api/api_shop';
import { PrintJob } from '../../model/entity/print_job';
import { PrintStatus, PrintTypes } from '../../model/entity/printJobSetting';
import { TranslateServiceBuilder } from '../../services/Translate/translateServiceBuilder';

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.component('Printer', {
    components: {
        QLoader: () => import('../../components/elements/QLoader.vue'),
        QTranslate: () => import("../../components/elements/QTranslate.vue"),
    },
  mixins: [
    notificationMixin,
  ],
  data() {
    return {
      printJobs: [],
      currentItem: {},
      errors: {},
      mode: '',

      isLoading: false,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    this.fetchPrintJobs();
  },
  computed: {
    setting() {
      return store.state.shopSetting;
    },
    isModalCancel(): boolean {
        return this.mode === 'cancel';
    },
  },
  methods: {
    // DOM
    handleClickEdit: function(item) {
        if (this.isLoading) {
            return;
        }
        this.setItem(item);
    },
    handleClickCloseEditor: function () {
        this.closeEditor();
    },
    handleClickSubmit: function () {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.currentItem.status = PrintStatus.STATUS_CANCELED;
        this.submitItem();
    },

    // LOGIC
    setItem: function(item) {
        this.currentItem = _.cloneDeep(item)
        this.mode = 'cancel';
    },
    closeEditor: function() {
        this.mode = '';
        this.cleanup();
    },
    cleanup: function (target = null) {
        if (target && this.errors[target]) {
            return this.errors[target] = null;
        }
        this.errors = {}
    },

    submitItem: function () {
      let message = '';
      api
        .printers_edit(
            { print_job: this.currentItem },
            { print_job_id: this.currentItem.id },
        )
        .then((response) => {
          if (response.data.status !== 'success') {
            message = response.data.message || translate('hall-0046', '保存に失敗しました。');
            this.showErrorNotification(message);
            this.errors = message;
            this.isLoading = false;
            return;
          }
          message = response.data.message;
          this.showSuccessNotification(message);
          this.closeEditor();
          this.fetchPrintJobs();
        })
        .catch((err) => {
            message = err.response.data.message || translate('hall-0046', '保存に失敗しました。');
            this.showErrorNotification(message);
            this.errors = message;
            this.isLoading = false;
        })
    },
    fetchPrintJobs() {
      this.isLoading = true;
      let message = '';
      api
        .printers_index()
        .then(response => {
          if (response.data.status != "success") {
            message = response.data.message || translate('hall-0048', 'データの取得に失敗しました。');
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.isLoading = false;
          this.printJobs = response.data.printJobs;
        })
        .catch((err) => {
            this.isLoading = false;
            message = err.response.data.message || translate('hall-0048', 'データの取得に失敗しました。');
            this.showErrorNotification(message);
            this.errors = message;
        })
    },

    isSchemaError(code): boolean {
      return code === "SchemaError";
    },
    isAcceptance(type): boolean {
        return type === PrintTypes.TYPE_ACCEPTANCE;
    },
    isKitchen(type): boolean {
        return type === PrintTypes.TYPE_KITCHEN;
    },
    isSettle(type): boolean {
        return type === PrintTypes.TYPE_SETTLE;
    },
    isCounterfoil(type): boolean {
        return type === PrintTypes.TYPE_COUNTERFOIL;
    },
    isTest(type): boolean {
        return type === PrintTypes.TYPE_TESTPRINT;
    },
  },
  filters: {
      priceFilter: function(price) {
          const formatter = new Intl.NumberFormat('ja-JP');

          return "¥" + formatter.format(price);
      },
      loadingStatus: function (loadingFlg) {
          return loadingFlg;
      },
  },
});
